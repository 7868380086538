import React from 'react';
import Preview from './preview';
import styled, { css } from 'styled-components';

import Volunteer from '../mp4/volunteer.mp4';
import Bowling from '../mp4/bowling.mp4';

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px 0;
  padding: 0 10%;
  padding-left: calc(10% + 100px);
  background: #ddd;

  @media (max-width: 570px) {
    flex-direction: column;
    padding: 0;
  }
`;

const Project = styled.div`
  margin-top: -20px;

  &:hover {
    z-index: 1;
  }

  ${props => css`
    transform: translateX(${props.index * -30}%)
      translateY(${props.index * 10}%);

    @media (max-width: 570px) {
      transform: translateX(${props.index * 10}%)
        translateY(${props.index * -20}%);
    }
  `}
`;

const Projects = () => {
  return (
    <Container>
      <Project index={0}>
        <Preview src={Volunteer} />
      </Project>
      {/* <Project index={1}>
        <Preview src={Volunteer} />
      </Project> */}
      <Project index={1}>
        <Preview src={Bowling} />
      </Project>
    </Container>
  );
};

export default Projects;
