import { useEffect, useRef } from 'react';

export default function useInterval(callback, duration = 300) {
  const interval = useRef();

  useEffect(() => {
    clearInterval(interval.current);
    interval.current = setInterval(callback, duration);
    return () => {
      clearInterval(interval.current);
    };
  }, [callback, duration]);
}
