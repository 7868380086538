import React, { useState, useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';

import useInterval from '../hooks/interval';
import useResize from '../hooks/resize';

import FitRow from './fitRow';

const Container = styled.div`
  opacity: ${props => (props.fade ? 1 : 0)};
  transform: ${props => (props.fade ? 'translateX(0)' : 'translateX(-30px)')};
  transition: opacity 0.8s ease-in-out, transform 0.8s ease-in-out;
`;

// font-size: clamp(48px, 5vw, 102px);
const Item = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: 800;
  text-transform: uppercase;
  white-space: nowrap;
  transition: color 0.8s ease-in-out;

  font-size: ${props => props.fontSize}px;
  line-height: ${props => props.fontSize + 5}px;
  color: ${props => (props.odd ? '#52c2b9' : '#37827c')};
  filter: hue-rotate(${props => props.index * -5}deg);

  ${props =>
    props.outline &&
    css`
      color: transparent;
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: #37827c;
    `}

  ${'' /* Ghost is first child - allow single phrases to fill */}
  &:nth-child(2):last-child,
  >span:first-child:last-child {
    flex: 1;
  }

  ${props =>
    props.black &&
    css`
      color: black;
    `}

  &>span {
  }
`;

const ROWS = [
  ['Design', 'Branding', 'A11y'],
  ['Development'],
  ['Analytics', 'Forms'],
  ['Search Optimization'],
  ['Content Management']
];

const List = () => {
  const [active, setActive] = useState(1);
  const numWords = ROWS.reduce((total, x) => (total += x.length), 0);
  const didMount = useRef();

  // Measure container size for FitRow
  const container = useRef();
  const width = useResize(container?.current);

  const onInterval = () => {
    if (active < numWords - 1) {
      setActive(active + 1);
    } else setActive(0);
  };

  useInterval(onInterval, 3000);

  useEffect(() => {
    if (!didMount.current) {
      didMount.current = true;
    }
  });

  return (
    <Container ref={container} fade={didMount.current}>
      {ROWS.map((row, y) => {
        return (
          <FitRow
            key={y}
            words={row}
            width={width}
            render={fontSize =>
              row.map((word, x) => {
                const index = y * row.length + x;
                const chunks = word.split(' ');
                return (
                  <Item
                    key={word}
                    fontSize={fontSize}
                    // index={index}
                    outline={chunks[0] === 'Development'}
                    odd={index % 2}
                    // black={index === active}
                  >
                    {chunks.map((w, iii) => (
                      <span>
                        {w}
                        {iii < chunks.length - 1 && <>&nbsp;</>}
                      </span>
                    ))}
                  </Item>
                );
              })
            }
          />
        );
      })}
    </Container>
  );
};

export default List;
