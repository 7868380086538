import React, { useRef } from 'react';
import styled from 'styled-components';

const Tint = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #52c2b9;
  background: linear-gradient(90deg, #52c2b9, #37827c);
  opacity: 0.7;
  transition: opacity 0.3s ease-in-out;
`;

const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: grayscale(100%);
`;

const Container = styled.div`
  position: relative;
  width: 320px;
  height: 200px;
  border-radius: 8px;
  perspective: 1000px;
  perspective-origin: center;
  transform: rotateX(10deg) rotateY(-10deg) translateZ(10px);
  transition: filter 0.3s ease-in-out,
    transform 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  box-shadow: 5px 5px 5px #00000033;
  overflow: hidden;

  &:hover {
    transform: rotateX(20deg) rotateY(-10deg) translateZ(20px) scale(1.1);

    ${Video} {
      filter: grayscale(0);
    }

    ${Tint} {
      opacity: 0;
    }
  }
`;

const Preview = ({ src }) => {
  const video = useRef();
  const play = () => video.current.play();
  const pause = () => video.current.pause();

  return (
    <Container onMouseEnter={play} onMouseLeave={pause}>
      <Video ref={video} muted loop>
        <source src={src} type="video/mp4" />
      </Video>
      <Tint />
    </Container>
  );
};

export default Preview;
